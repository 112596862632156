.screen {
    min-height: 100vh;
    background-color: #4761F6;
    overflow: hidden;
    /* background-color: #4761F6; */
}
.center {
    display: flex;
    justify-content: center;
}
.service-grid {
}
.white-text {
    color: white;
}
.big-text {
    font-size: 20px;
}
.very-big-text {
    font-size: 32px;
}